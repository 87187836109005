interface IGoogleTagManagerEventTypes {
  destination: string;
  origin: string;
  itineraries: string;
  date: string;
  hotelAvailabilities: string;
  flightAvailabilities: string;
  dpAvailabilities: string;
}

export const GoogleTagManagerEventTypes: IGoogleTagManagerEventTypes = {
  destination: "destination",
  origin: "origin",
  itineraries: "itineraries",
  date: "date",
  hotelAvailabilities: "hotel_availabilities",
  flightAvailabilities: "flight_availabilities",
  dpAvailabilities: "dp_availabilities",
};
